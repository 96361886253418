<template>
  <div style="text-align: center; padding-top: 50px">
    <van-loading size="24px">用户授权中...</van-loading>
  </div>
</template>
<script>
import "./index.less";
import { getQueryString, getQueryString2 } from "@/utils/utils";
import { Loading, Toast } from "vant";
export default {
  components: {
    [Loading.name]: Loading,
    [Toast.name]: Toast
  },
  data() {
    return {
      code: "",
      TOKEN: "",
      jumpUrl: ""
    };
  },
  mounted() {
    // this.code = window.localStorage.getItem('code')
    // this.TOKEN = window.localStorage.getItem('TOKEN')
    //判断用户是否已经授权登录了（code）
    this.getCode();
    // 模拟用户信息
    //this.mock();
  },
  methods: {
    mock() {
      // const obj1 = {
      // 	OpenID: 'oW41H6_lpuHPx7Tznz5J1QFSFh6o',
      // 	headUrl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/2Mdk3SiacBZgB8gxtRUrUuMJLQDUDGdkl6SOiaObzjOr7Zshl9ibOP3wb1koMJhREz0zpEpHmHoH4ye1FObT7CJ9A/132',
      // 	userName: '18855386127',
      // }
      // window.localStorage.setItem("wxuserInfo", JSON.stringify(obj1));
      //  return this.$router.replace({
      // 	path: "ChoseRole",
      // });

      // 家长
      const loginUser = {
        ID: "16313650123523072",
        UserName: "15212233039",
        RealName: "何化云",
        Password: "91fccaeeff9ae8576a4fc7182c6e6873",
        CellPhone: "15212233039",
        IDCard:
          "T0oCajtdf9VbfzabABelvvK/RuUcU1+T7zTGv62nIIq0ig2AblXe/Sh0ep62OYWN+HnpqUHbJs1dttwWU2NaJnzdU01jx+k9EJaenjn0LOChy9AUJtW6LjfKfdNrMrj+XK1ItlkAk7HrWETbOzxXeXDGRqNl/cpJszmHjuTXVXk=",
        WeChatID: null,
        ProfilePicture: "",
        UserType: 2,
        CreateUserID: null,
        CreateDate: "2022-03-21T11:13:49.06",
        LastDate: "2022-04-27T16:38:19.0633056+08:00",
        Token: "341a49d5-d9a7-4925-a616-99d9be1991f0",
        Expire: "2024-05-04T16:38:19.0633056+08:00",
        IsAccountDisabled: false,
        SchoolID: "16298368450642944",
        IsDelete: false,
        RelationID: "16313650123441152",
        RoleIDList: "16324041757131776",
        uploadList: null,
        OpenID: "oqF0z56zGPsJb41bqwkQmPVhjQgw",
        HeadUrl:
          "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erVwO0FYic26GEYvAKqBpb1vlReuqc28libXton3Vk2KSibvVKpAKuNyicEPCLVUonicE4TymurUDHBp2A/132",
        ParentType: 99,
        PictureKey: null,
        ChildrenList: null,
        OpenChildren: null,
        ReturnSchoolID: "16298368450642944",
        SchoolName: "盛世",
        IsAdministration: false,
        Principal: false
      };
      window.localStorage.setItem("userInfo", JSON.stringify(loginUser));
      const obj = {
        OpenID: loginUser.OpenID,
        headUrl: loginUser.HeadUrl,
        userName: loginUser.UserName
      };
      window.localStorage.setItem("wxuserInfo", JSON.stringify(obj));
      // 存储UserType 1 学生，2职工，3家长
      window.localStorage.setItem("UserType", loginUser.UserType);
      window.localStorage.setItem("TOKEN", loginUser.Token);
      window.localStorage.setItem("SchoolID", loginUser.ReturnSchoolID);
      window.localStorage.setItem(
        "IsAdministration",
        loginUser.IsAdministration
      );
      // 是否为院长
      window.localStorage.setItem("Principal", loginUser.Principal);
      if (loginUser.UserType == 3 && loginUser.OpenChildren) {
        window.localStorage.setItem(
          "childrenInfo",
          JSON.stringify(loginUser.OpenChildren)
        );
      }
      window.localStorage.setItem("loginUserID", loginUser.ID);
      this.$router.push("Home");
    },
    // 授权获取code
    getCode() {
      const that = this;
      var local = "http://kindergather.whsedu.cn/#/Authority"; // + (window.localStorage.getItem('lastPath') || '') // 获取页面url
      //var local = "http://kindergathertest.whsedu.cn/#/Authority"; // + (window.localStorage.getItem('lastPath') || '') // 获取页面url
      //高科幼教
      //var appid = "wx0826afb089d6465d";
      //师范附属
      var appid = "wx02e2bf4a4d060239";
      this.code = getQueryString("code");
      // if(getQueryString2('url')){
      //   window.localStorage.setItem('jumpUrl', getQueryString2('url'))
      // }
      const hrefs = window.location.href.split("?");
      if (hrefs[1] && (this.code == null || this.code === "" || !this.code)) {
        let a = hrefs[1].replace("%3F", "?");
        a = a.replace("%3D", "=");
        var index = a.indexOf("=");
        if (hrefs[1].indexOf("groupID") != -1) {
          window.localStorage.setItem(
            "groupID",
            a.substring(index + 1, a.length)
          );
        } else {
          window.localStorage.setItem(
            "jumpUrl",
            a.substring(index + 1, a.length)
          );
        }
      }

      if (!this.code || this.code == null || this.code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      } else {
        that.$axios
          .post("/api/WeChat/OAuthLogin", {
            Code: this.code
          })
          .then(res => {
            if (res.code === 200) {
              // 需要得到的用户信息存起来，注册的时候会用到，未注册的调往注册
              const data = res.data;
              if (data.IsLocalUser) {
                if (data.OnlyOneIdentity) {
                  if (
                    !data.loginUser.OpenChildren &&
                    data.loginUser.UserType == 3
                  ) {
                    const obj = {
                      OpenID: data.loginUser.OpenID,
                      headUrl: data.loginUser.HeadUrl,
                      userName: data.loginUser.UserName
                    };
                    window.localStorage.setItem(
                      "wxuserInfo",
                      JSON.stringify(obj)
                    );
                    this.$router.replace({
                      path: "ChoseRole"
                    });
                  } else {
                    window.localStorage.setItem(
                      "userInfo",
                      JSON.stringify(data.loginUser)
                    );
                    // 存储UserType 1 学生，2职工，3家长
                    window.localStorage.setItem(
                      "UserType",
                      data.loginUser.UserType
                    );
                    window.localStorage.setItem("TOKEN", data.loginUser.Token);
                    window.localStorage.setItem(
                      "SchoolID",
                      data.loginUser.ReturnSchoolID
                    );
                    window.localStorage.setItem(
                      "IsAdministration",
                      data.IsAdministration
                    );
                    // 是否为院长
                    window.localStorage.setItem("Principal", data.Principal);
                    if (
                      data.loginUser.UserType == 3 &&
                      data.loginUser.OpenChildren
                    ) {
                      window.localStorage.setItem(
                        "childrenInfo",
                        JSON.stringify(data.loginUser.OpenChildren)
                      );
                    }

                    window.localStorage.setItem(
                      "loginUserID",
                      data.loginUser.ID
                    );
                    if (
                      window.localStorage.getItem("jumpUrl") == null ||
                      window.localStorage.getItem("jumpUrl") == ""
                    ) {
                      this.$router.replace("Home");
                    } else {
                      const url = window.localStorage.getItem("jumpUrl");
                      window.localStorage.removeItem("jumpUrl");
                      this.$router.replace(url);
                    }
                  }
                } else {
                  const obj = {
                    OpenID: data.loginUser.OpenID,
                    headUrl: data.loginUser.HeadUrl,
                    userName: data.loginUser.UserName
                  };
                  window.localStorage.setItem(
                    "wxuserInfo",
                    JSON.stringify(obj)
                  );
                  this.$router.replace({
                    path: "ChangeRole"
                  });
                }
              } else {
                const obj = {
                  OpenID: data.OpenID,
                  headUrl: data.headUrl,
                  userName: data.userName
                };
                window.localStorage.setItem("wxuserInfo", JSON.stringify(obj));
                this.$router.replace({
                  path: "ChoseRole"
                });
                // window.location.href = 'http://tjb.whsedu.cn/#/RegisterRole'
              }
            } else {
              window.localStorage.clear();
              // Toast.fail(res.data || res.msg || '请求失败')
            }
          });
      }
    }
  }
};
</script>
